body {
  margin: 0;
  font-family: 'ModernEra', 'QuincyCF';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Column {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
}

.DoubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.TripleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
}
