.TabletContainer{
  display: none;
}

@media (min-width: 700px) {
  .TabletContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 750px;
    align-self: center;
    margin-top: 100px;
    align-items: center;
  }
} 

@media (min-width: 1200px) {
  .TabletContainer {
    max-width: 100%;
  }
} 