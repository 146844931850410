.HeaderContainer {
  height: 80px;
  box-shadow: 0 3px 12px 0 rgba(7, 82, 249, 0.15);
  position: sticky;
  top: 0;
  z-index: 7;
  background-color: #fff;
}
.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  height: 100%;
}

.Logo img {
  width: 97px;
}

@media (min-width: 767px) {
  .Logo img {
    width: 144px;
  }
}
