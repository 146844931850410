.Container {
    margin: 0 auto;
    width: 90%;
}

.Title {
    font-family: ModernEra-ExtraBold, sans-serif;
    font-size: 24px;
    text-align: center;
}

.CardsWrapper{
    display: flex;
    flex-direction: column;
}

@media (min-width: 767px) {
    .Container {
        margin-top: 70px;
        max-width: 850px;
    }
    .Title {
        font-size: 33px;
    }
    .CardsWrapper{
        flex-direction: row;
    }
}
