.container{
  display: flex;
}
.doubts{
  display: none;
}
.OutlineButton{
  width: 160px;
  height: 40px;
  border-radius: 10px;
  border: solid 2px #ff477e;
  background-color: #ffffff;
  color: #ff477e;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  font-family: ModernEra-ExtraBold, sans-serif;
  text-decoration: none;
}
.textBtnWrapper{
  display: flex;
}
.wp{
  display: none;
  margin-left: 4px;
}
@media (min-width: 767px) {
  .wp{
    display: block;
  }
  .OutlineButton{
    width: 260px;
  }
  .doubts{
    display: block;
    margin-right: 20px;
    font-size: 16px;
  }
}
