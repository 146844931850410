.container {
    width: 100%;
    background-image: linear-gradient(#e3e9fc, #fefaf9);
    border-radius: 10px;
    margin: 24px 0 0 0;
}

.containerChile {
    width: 100%;
    background-image: linear-gradient(#e3e9fc, #fefaf9);
    border-radius: 10px;
    margin: 24px 0 0 0;
}

.title {
    width: 80%;
    font-family: QuincyCFBold, serif;
    font-size: 28px;
    text-align: center;
    margin: 0 auto;
}
.titleChile {
font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 48px; 
text-align: center;
letter-spacing: 0.0025em;
color: #212121;
margin: 0 auto;
}

.margin{
    width: 90%;
    margin: 0 auto;
    padding: 80px 0 54px 0;
}


.marginChile{
    width: 90%;
    margin: 0 auto;
    padding: 80px 0 54px 0;
}

.intro{
    font-family: ModernEra, sans-serif;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
}
.introCL{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.0015em;
    display: flex;
    align-self: center;
    width: 100%;
    margin: 20px auto;
    color:#616161
}

.imageWrapper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
}
.image, .deskImage, .imageOne{
    width: 100%;
    height: auto;
}

.imageOne{
    order: 1;
}

.deskImage{
    display: none;
}

@media (min-width: 767px) {
    .margin{
        max-width: 850px;
    }
    .marginChile{
        max-width: 850px;
    }
    .title{
        font-size: 2.5em;
    }
    .titleChile{
        font-size: 32px;
        margin: 0 auto;

    }
    .intro{
        font-size: 1.5em;
    }
    .introCL{
        font-size: 20px;
        width: 80%;
    }
    .imageWrapper{
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }
    .imageOne{
        order: 0;
    }
    .image, .deskImage, .imageOne{
        width: 32%;
        margin: 4px;
        max-height: 166px;
    }
    .deskImage{
        display: block;
    }
}
