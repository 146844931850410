.Container {
    margin: 40px auto;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(210, 221, 255, 0.39);
}

.Title {
    font-family: ModernEra-ExtraBold, sans-serif;
    font-size: 20px;
}

.SubTitle, .SubTitleBolder{
    font-size: 16px;
    line-height: 19px;
}
.SubTitleBolder{
    font-family: ModernEra-ExtraBold, sans-serif;
}
.ImageWrapper{
    width: 100%;
    height: 175px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.Image{
    width: 166px;
    height: auto;
}

.TextWrapper{
    margin: 0 auto;
    width: 90%;
    padding-bottom: 24px;
}

.PriceWrapper{
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    height: 67px;
    margin: 24px 0;
    padding: 4px 16px;
}
.OfferText{
    padding-bottom: 10px;
    font-size: 12px;
    margin: 0;
}

.offerWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.PriceOffer{
    font-family: Poppins, serif;
    font-size: 20px;
    font-weight: bold;
    color: #e61e4d;
}

.NewPrice{
    font-size: 16px;
    color: #bdbdbd;
    text-decoration: line-through;
    font-family: ModernEra-ExtraBold, sans-serif;
}
.Button{
    width: 100%;
    height: 40px;
    margin: 10px auto;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 rgba(255, 71, 126, 0.3);
    background-color: #e61e4d;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-family: ModernEra-ExtraBold, sans-serif;
    text-decoration: none;
}

.OutlineButton{
    width: 100%;
    height: 40px;
    margin: 10px auto;
    border-radius: 10px;
    border: solid 2px #212121;
    background-color: #ffffff;
    color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: ModernEra-ExtraBold, sans-serif;
    text-decoration: none;
}

.withOutWorriesWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
}

.iconDontWorry{
    width: 30%;
}

.withOutWorries{
    font-size: 12px;
    line-height: 17px;
}

.withOutWorries span {
    font-weight: 800;
}

.icon{
    width: 100px;
    margin-bottom: 11px;
}

@media (min-width: 767px) {
    .Container{
        max-width: 316px;
    }
    .SubTitle{
        height: 115px;
    }
}

