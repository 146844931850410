.Container {
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: QuincyCF;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
  min-height: 80px;
}

.Container > p {
  margin: 0;
  width: 100%;
  min-width: 200px;
}

.starsBranch {
  order: 3;
}

.forbes, .dinero{
  order: 2;
}

.expasion, .ipro {
  order: 4;
}

.excelsor, .lr {
  order: 1;
}

.techCrunch {
  order: 5;
}
/* -------------Chile Styles (CL) ---------- */
.starsBranchCL {
  order: 3;
}

.forbesCL{
  order: 1;
}

.expasionCL {
  order: 4;
}

.excelsorCL {
  order: 5;
}

.techCrunchCL {
  order: 2;
}
/* ------------------------------------ */
.excelsor > img, .excelsorCL > img {
  width: 171px;
  height: 36px;
}

.lr > img{
  width: 40px;
  height: 48px;
}

.starsBranch > img, .starsBranchCL > img {
  width: 180px;
}

.forbes > img, .dinero > img, .forbesCL > img{
  width: 147.5px;
  height: 36px;
}

.expasion > img, .ipro > img, .expasionCL > img{
  width: 176px;
  height: 36px;
}

.techCrunch > img{
  width: 176px;
  height: 36px;
}

.techCrunchCL > img{
  width: 223.69px;
  height: 32px;
}

@media (min-width: 700px) {
  .starsBranch {
    order: 2;
  }
  
  .forbes, .dinero {
    order: 3;
  }
  
  .expasion, .ipro {
    order: 4;
  }
  
  .excelsor, .lr {
    order: 1;
  }
  
  .techCrunch {
    order: 5;
  }

  /* ------------------ CL-------------- */
  .starsBranchCL {
    order: 2;
  }
  
  .forbesCL {
    order: 1;
  }
  
  .expasionCL{
    order: 4;
  }
  
  .excelsorCL {
    order: 5;
  }
  
  .techCrunchCL {
    order: 3;
  }
} 

@media (min-width: 1200px) {

  .starsBranch {
    order: 3;
  }
  
  .forbes{
    order: 2;
  }
  
  .expasion {
    order: 4;
  }
  
  .excelsor {
    order: 1;
  }
  
  .techCrunch {
    order: 5;
  }

  /* ------------------ CL-------------- */
  .starsBranchCL {
    order: 3;
  }
  
  .forbesCL{
    order: 1;
  }
  
  .expasionCL {
    order: 4;
  }
  
  .excelsorCL {
    order: 5;
  }
  
  .techCrunchCL {
    order: 2;
  }
} 