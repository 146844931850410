.MainContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 87px;
}

.lastNote{
  background-color: #fce1e4;
  width: 100%;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textLastNote{
  width: 80%;
  font-family: ModernEra, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.NewsContainer {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: space-between;
  align-items: center;
  padding: 70px 0px;

}

.NewsImage {
  margin: 0px 16px;
}

.News3 {
  height: 78.3px;
}

.News5 {
  height: 71.5px;
}

.News4 {
  height: 98.5px;
}

.News2 {
  height: 81.4px;
}

.News1 {
  height: 76.6px;
}

.FooterContainer {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 87px;
  align-items: center;
}

.Contact {
  margin-left: 0;
}

.WhatsContainer {
  font-family: HelveticaNeue, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.SocialNetworks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 32px;
  width: 28%;
}

.Icons {
  background-color: #000000;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.WhatsappIcon {
  background-image: url('../../Assets/images/svg/whatsapp.svg');
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 8px;
}
.WhatsappIconChile {
  background-image: url('../../Assets/images/svg/whatsapp.svg');
  background-repeat: no-repeat;
  width: 26.12px;
  height: 26.5px;
  display: block;
  margin: 8px 0px 0 0px;
}

.FacebookIcon {
  background-image: url('../../Assets/images/svg/facebook.svg');
  background-repeat: no-repeat;
  height: 22px;
  width: 11.8px;
  display: block;
}

.InstagramIcon {
  background-image: url('../../Assets/images/svg/instagram.svg');
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  display: block;
}

.YoutubeIcon {
  background-image: url('../../Assets/images/svg/youtube.svg');
  background-repeat: no-repeat;
  width: 23.5px;
  height: 16.5px;
  display: block;
}

@media (min-width: 769px) {
  .SocialNetworks {
    width: 12%;
  }
}
