.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 89%;
  margin: 36px auto 0 auto;
}

.ApolloImgContainer {
  margin-bottom: 32px;
}

.ApolloMore {
  height: 146px !important;
}

.ApolloMoons {
  height: 166px;
}

.MsgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Message, .Description {
  font-family: 'Poppins', serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.default{
  text-align: left;
}

.Message {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
}

.Description {
  margin-top: 16px;
  font-size: 24px;
  line-height: 1.25;
}

.DescriptionSmaller {
  margin-top: 16px;
  font-size: 20px;
  line-height: 1.3;
}

.MessageMoons {
  min-width: 274px;
}

@media (min-width: 767px) {
  .MainContainer {
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
    width: 95%;
  }

  .ApolloImgContainer {
    margin-bottom: 0;
  }

  .Message {
    margin: 0;
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.27;
    max-width: 436px;
  }

  .Description, .DescriptionSmaller {
    font-size: 34px;
    line-height: 1.24;
  }
  .default{
    font-size: 26px;
  }
}
