.promoAppointmentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  padding: 0 3em 0 3em;
}

.promoAppointmentTitle{
  font-family: 'Inter';
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 32px;
color: #000000;

width: 298px;
height: 96px;
font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 48px;
text-align: center;
letter-spacing: 0.0025em;
margin: 0 0 2em 0;
}

.promoAppointmentTitleMobile{
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #000000;

  width: 298px;
  height: 96px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.0025em;
  margin: 0 0 2em 0;
  display: none;

}

.promoAppointmentContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin-bottom: 2em;

  max-width: 366px;
}


.promoAppointmentHeader{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  width: 100%;
  height: 24px;
  background: #7E4DFF;
  border-radius: 8px 8px 0px 0px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: 0;

}

.promoAppointmentContent button{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;
width: 308px;
height: 44px;
background: #E61E4D;
box-shadow: 0px 2px 4px rgba(230, 30, 77, 0.16);
border-radius: 8px;
border: none;
font-family: 'Inter';
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.0125em;
color: #FFFFFF;
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 2em;
cursor: pointer;
}

.promoAppointmentSubtitle{
width: 116px;
height: 32px;

font-family: 'Inter';
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 32px;
color: #000000;
text-align: center;
margin: 36px auto 16px auto;
}
.promoAppintmentText{
font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
color: #616161;
margin: 0;

}

/* -------------------------FREE APPOINTMENT STYLE-------------------- */
.freeAppointmentContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1em 0 1em;
}

.freeAppointmentText{
font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #616161;
margin: 0 auto 16px auto;
}
.freeAppointmentText span{
  font-weight: 700;
}

.freeAppointmentTitle{
  height: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #E61E4D;
  margin: 0rem 0 16px 0;
}

.freeAppointmentPrice{
width: 69px;
height: 24px;
font-family: 'Poppins';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-decoration-line: line-through;
color: #757575;
margin: 0px auto 8px auto;
}

.freeAppointmentTimer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
/* padding: 0 3em 0 3em; */
}

.timerContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timerContainer p{
  font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: center;

/* Color/Gray scale/600 */
color: #757575;
  margin: 0 auto 30px auto
}
.timer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.timer span{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
align-items: center;
padding: 12px;

width: 51px;
height: 64px;

background: #F5F5F5;
border-radius: 8px;

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 8px;


width: 27px;
height: 40px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 40px;
text-align: center;
color: #000000;
flex: none;
order: 0;
flex-grow: 0;
margin: 10px 7px 10px 0px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 56px;
line-height: 56px;
}

.timer p{
width: 53px;
height: 20px;

font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #757575;
}

.separatorTimerContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 0.6em;
}
.separatorTimer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
width: 20px;
height: 64px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 64px;
line-height: 64px;
text-align: center;
color: #9E9E9E;
flex-grow: 0;
margin: 0px 9px 2px 0px;
}
/* -------------------------PROMO APPOINTMENT STYLE-------------------- */


/* --------------------------MEDIA QUERIES------------------------------- */

@media (min-width: 767px) {
  .promoAppointmentContainer{
    flex-direction: row;
    justify-content: space-around;
}

.promoAppointmentTitle{
  display: none;
}
.promoAppointmentTitleMobile{
  display: block;
width: 298px;
height: 96px;
font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 48px;
letter-spacing: 0.0025em;

color: #000000;
flex: none;
order: 0;
flex-grow: 0;
margin: 24px 0px;
text-align: inherit;
}
.promoAppointmentContent{
  order:2
}
.promoAppintmentText{
  max-width: 465px;
  margin-right: 2em;
}

}

@media (max-width: 420px) {
  .promoAppointmentContainer{
    padding: 0 1em 0 1em;
  }
}
@media (max-width: 390px) {
  .promoAppointmentContainer{
    padding: 0 1em 0 1em;
  }
  .freeAppointmentContainer {
    width: 100%;
}
.timer{
  width: 17px;
  height: 30px;
}
.separatorTimer{
  width: 10px;
  height: 54px;
  margin: -0.5em 2rem 0em 2rem;
}

.freeAppointmentPrice{
  margin: 0px auto 30px auto;
  }
  .timerContainer p{

    color: #757575;
    margin: 25px auto 30px auto
  }
  .freeAppointmentTitle{
    margin: 0rem 0 25px 0;
  }
    
}


@media (max-width: 360px) {

.promoAppointmentContainer{
  padding: 0 1em 0 1em;
}

  .freeAppointmentContainer {
    width: 100%;
}
.timer span{
  width: 17px;
  height: 30px;
}
.separatorTimer{
  width: 10px;
  height: 54px;
  margin: -0.5em 1rem 0em 1rem;
}

.promoAppointmentContent button {
  width: 220px;

  }

  .freeAppointmentTitle{
    margin: 0rem 0 25px 0;
  }

}
