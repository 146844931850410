.modalVeilContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background: #807d7dd9;
  overflow-x: hidden;
  transition: 0.5s;
}

.promoModalContainer{
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  outline: none;
  width: 312px;
  left: 0%;
  top: 0%;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  margin: 0 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  align-items: center;
  justify-items: center;
  outline: none;

}
.promoImgContainer{
  text-align: center;
  margin-top: 30px;
  outline: none;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
}

.promoImgContainer img{
  width: 100%;
  height: 100%;
}

.promoModalTitle{
  width: 222px;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
  margin: 0 auto 1rem auto;
}

.promoModalText{
  width: 280px;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #757575;
  margin: 0 auto 2rem auto;
}

.promoModalContainer button{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;
width: 280px;
height: 40px;
background: #E61E4D;
box-shadow: 0px 2px 4px rgba(230, 30, 77, 0.16);
border-radius: 8px;

font-family: 'Inter';
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.0125em;

color: #FFFFFF;

flex: none;
order: 0;
flex-grow: 0;
margin: 0rem 10rem 2rem 10rem;
border: none;
cursor: pointer;
}

.skipButton{
font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
letter-spacing: 0.0125em;
text-decoration-line: underline;
color: #212121;

flex: none;
order: 0;
flex-grow: 0;
margin: 0rem auto 2rem auto;
cursor: pointer;
}

@media (min-width: 768px) {
  .promoModalContainer{
    width: 600px;;
  }
  .promoImgContainer{
    height: 251px;
  }
  .promoModalText{
    width: 90%;
  }
}

@media (max-width: 320px) {
  .promoModalContainer {
     width: 279px;
  }

.promoModalContainer button{
  width: 90%;
  }
  .promoModalText{
    width: 90%;
    margin-bottom: 2rem;
  }
}
